<template>
  <span>{{ formatted }}</span>
</template>
<script>
import moment from 'moment-timezone'

export default {
  name: 'Date',
  props: [ 'value', 'format', 'default' ],
  computed: {
    formatted: function () {
      if (this.value) {
        return moment(this.value).format(this.format)
      } else if (this.default) {
        return this.default
      }
      return null
    }
  }
}
</script>
