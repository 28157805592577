<template>
  <b-modal :title="strategy.id > 0 ? 'Edit Strategy' : 'Add Strategy'" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col md="3">
        <label>Name</label>
        <b-form-input type="text" v-model.trim="strategy.name" :state="state($v.strategy.name)" @input="touch($v.strategy.name)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="3">
        <label>Start Date</label>
        <flat-pickr class="form-control" v-model.trim="strategy.startAt" :state="state($v.strategy.startAt)" @input="touch($v.strategy.startAt)" :config="dateConfig" placeholder="Start Date"></flat-pickr>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="3">
        <label>Tax Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="strategy.taxRate" :state="state($v.strategy.taxRate)" @input="touch($v.strategy.taxRate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Required. Decimals Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
      <b-col md="3">
        <label>Capital Gains Rate</label>
        <b-input-group class="rate">
          <b-input type="text" placeholder="" v-model="strategy.capitalGainsTaxRate" :state="state($v.strategy.capitalGainsTaxRate)" @input="touch($v.strategy.capitalGainsTaxRate)"></b-input>
          <b-input-group-append><b-input-group-text>%</b-input-group-text></b-input-group-append>
          <b-form-invalid-feedback>Required. Decimals Only</b-form-invalid-feedback>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col md="3">
        <b-form-checkbox size="sm" v-model="strategy.locked"> Lock Strategy?</b-form-checkbox>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        {{ strategy.id > 0 ? 'Save' : 'Continue' }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  props: [ 'defaultName' ],
  components: {
    flatPickr
  },
  name: 'StrategyModal',
  data () {
    return {
      dateConfig: {
        altFormat: 'm/d/Y',
        altInput: true
      },
      strategy: {
        name: this.defaultName,
        startAt: null,
        taxRate: null,
        capitalGainsTaxRate: null
      },
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (strategy) {
      this.active = true
      if (strategy) {
        this.strategy = { ...strategy }
      } else {
        this.strategy = {
          name: this.defaultName,
          startAt: new Date(),
          taxRate: null,
          capitalGainsTaxRate: null
        }
      }
    },
    save () {
      let strategy = this.strategy
      this.$emit('save', { ...strategy })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:addStrategy', (strategy) => {
      this.show(strategy);
      this.$nextTick(() => { this.$v.$reset() })
    })

    this.$bus.$on('modal:editStrategy', (strategy) => {
      this.show(strategy);
      this.$nextTick(() => { this.$v.$reset() })
    })
  },
  validations: {
    strategy: {
      name: {
        required
      },
      taxRate: {
        required
      },
      capitalGainsTaxRate: {
        required
      }
    }
  }
}
</script>
<style>
  .flatpickr-input[readonly] {
    background: inherit;
  }
  .input-group.is-invalid > .invalid-feedback {
    display: block;
  }
  .input-group.is-invalid > input.form-control {
    border-color: #f86c6b;
  }
  .input-group.is-valid > input.form-control {
    border-color: #4dbd74;
  }
</style>
