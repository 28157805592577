<template>
  <b-card>
    <div slot="header">
      Client Strategies
      <div class="card-header-actions">
        <b-btn size="sm" variant="success" @click="newStrategy" >
          <i class="icon-plus"></i> Add New
        </b-btn>
      </div>
    </div>
    <b-card-body>
      <b-row>
        <b-col sm="12" v-if="strategies.length == 0">
          <p>No Strategies. <b-btn size="sm" variant="outline-success" @click="newStrategy"><i class="icon-plus"></i> Add New</b-btn></p>
        </b-col>
        <b-col sm="12" v-if="strategies.length > 0">
          <b-table hover striped small responsive="sm" :items="strategies" :fields="strategyFields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
            <template v-slot:head(actions)></template>
            <template v-slot:cell(years)="data">
              {{ data.value }} years
            </template>
            <template v-slot:cell(taxRates)="data">
              <small>Income:</small> <percent :value="data.item.taxRate"></percent><br />
              <small>Capital Gains:</small> <percent :value="data.item.capitalGainsTaxRate"></percent>
            </template>
            <template v-slot:cell(startAt)="data">
              <date :value="data.value" format="MM/DD/YYYY"></date>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button size="sm" variant="ghost-danger" @click.stop="deleteStrategy(data.item)">
                <i class="fa fa-remove"></i>
              </b-button>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" :total-rows="getRowCount(strategies)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
          </nav>
        </b-col>
      </b-row>
      <strategy-modal :defaultName="addNewName" @save="addStrategy"></strategy-modal>
    </b-card-body>
  </b-card>
</template>

<script>
import StrategyModal from '@/components/modals/StrategyModal'
import { mapGetters } from 'vuex'
import Date from '@/components/text/Date'
import Percent from '@/components/text/Percent'
import moment from 'moment'

export default {
  name: 'Strategies',
  components: {
    Date,
    StrategyModal,
    Percent
  },
  data: () => {
    return {
      strategies: [],
      strategyFields: [
        { key: 'name', label: 'Name' },
        { key: 'years', label: 'Duration', class: 'numeric' },
        { key: 'taxRates', label: 'Tax Rates', class: 'numeric' },
        { key: 'startAt', label: 'Start Date', class: 'numeric' },
        { key: 'actions', label: '', class: 'text-right' }
      ],
      addNewName: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient'
    })
  },
  methods: {
    addStrategy (strategy) {
      strategy.clientId = this.$route.params.id
      this.$api.strategies.create(strategy)
        .then(({ data }) => {
          const userLink = this.userLink(data.id)
          this.$router.push({path: userLink})
        })
        .catch(() => {
          this.$snotify.error('Error Saving Strategy')
        })
    },
    newStrategy () {
      this.$bus.$emit('modal:addStrategy')
    },
    fetchStrategies () {
      this.$api.strategies.client(this.$route.params.id)
        .then((response) => {
          this.strategies = response.data.sort((a, b) => {
            if (moment(a.date) > moment(b.date)) {
              return 1
            } else if (moment(a.date) < moment(b.date)) {
              return -1
            } else {
              return 0
            }
          })
          this.addNewName = 'Strategy ' + (response.data.length + 1)
        })
        .catch(() => {
          this.$snotify.error('Error Fetching Client Strategies')
        })
    },
    deleteStrategy (strategy) {
      if (confirm('Are you sure?')) {
        this.$api.strategies.delete({ id: strategy.id })
          .then(() => {
            this.$snotify.success('Successfully Deleted Strategy')
            let index = this.strategies.findIndex((o) => o.id === strategy.id)
            if (index >= 0) this.strategies.splice(index, 1)
          })
          .catch(() => {
            this.$snotify.error('Error Deleting Strategy')
          })
      }
    },
    getRowCount (items) {
      return items.length
    },
    userLink (id) {
      return `strategies/${id.toString()}`
    },
    rowClicked (item) {
      const userLink = this.userLink(item.id)
      this.$router.push({path: userLink})
    },
  },
  created () {
    this.$store.dispatch('global/clearStrategy')
    if (this.currentClient.id && this.currentClient.id == this.$route.params.id) {
      this.strategies = this.currentClient.strategies
    } else {
      this.$store.dispatch('global/getClient', {
        id: this.$route.params.id
      })
    }
  },
  watch: {
    currentClient: {
      handler: function () {
        this.strategies = this.currentClient.strategies
      },
      deep: true
    }
  }
}
</script>

<style>
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
</style>
